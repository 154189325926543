<template>
	<div>
		<v-card class="pa-5">
			<v-row>
				<v-col cols="12" sm="10">
					<h1 class="display-1 mb-4">
						{{ company.name }}
						
						<v-tooltip right :color="$cssGlobal.getStatus($listUtils.elementStatus[company.status])">
							<template v-slot:activator="{ on, attrs }">
								<v-btn v-bind="attrs" v-on="on" :color="$cssGlobal.getStatus($listUtils.elementStatus[company.status])" fab dark height=" 15px" width="15px" class="mb-3"></v-btn>
							</template>
							<span>{{ $listUtils.elementStatus[company.status] }}</span>
						</v-tooltip>
					</h1>

					<p class="subtitle-2">
						<span>
							<v-icon small>mdi-alpha-e-circle-outline</v-icon> Entreprise n°{{ company.nCompany }}
						</span>
						<span v-if="company.creationDate">
							・ <v-icon small v-if="company.creationDate">mdi-clock-outline</v-icon> Ajouté à Katana le {{ $functions.getDateFormat(company.creationDate, "DD/MM/YYYY") }}
						</span>
						<span v-if="company.birthdate">
							・ <v-icon small>mdi-cake</v-icon> Anniversaire {{ $functions.getDateFormat(company.birthdate, "DD/MM/YYYY") }}
						</span>
					</p>

					<v-row v-if="company.phone || company.email">
						<v-col cols="12" class="pb-0">
							<h3 class="font-weight-light">Contact</h3>
						</v-col>
						<v-col v-if="company.phone">
							<p>Téléphone : {{ company.phone }}</p>
						</v-col>
						<v-col v-if="company.email">
							<p>Email : {{ company.email }}</p>
						</v-col>
						<v-col v-if="company.website">
							<p>Site web : <a :href="`https://${company.website}`" target="_blank">{{ company.website }}</a></p>
						</v-col>
					</v-row>

					<v-row v-if="company.address.street">
						<v-col cols="12" class="pb-0">
							<h3 class="font-weight-light">Adresse</h3>
						</v-col>
						<v-col>
							<p>
								{{ company.address.street }}<br />
								{{ company.address.region }}
							</p>
						</v-col>
						<v-col>
							<p>
								{{ company.address.postalCode }} {{ company.address.city }}<br />
								{{ company.address.country }}
							</p>
						</v-col>
					</v-row>
				</v-col>

				<v-col cols="12" sm="2">
					<v-img v-if="company.avatar" contain :src="$functions.getImgLink(company.avatar)"></v-img>
				</v-col>

				<v-col>
					<h3 v-if="company.bio" class="font-weight-light">Description</h3>
					{{ company.bio }}

					<h4 class="my-3 font-weight-light" v-if="fromCustomer">Ce client est rattaché à cette entreprise.</h4>
				</v-col>

				<v-col cols="auto">
					<v-btn v-if="fromCustomer" outlined small class="mt-3" @click="$router.push(`/record/company/${company.id}`)">Voir l'entreprise</v-btn>
					<v-btn v-else outlined small class="mt-3" @click="dialogs.modify = true">Modifier</v-btn>
				</v-col>
			</v-row>
		</v-card>

		<v-card class="pa-5 mt-5" v-if="large">

			<v-row>

				<v-col cols="12" sm="3" v-if="admin">
					<v-col cols="12" class="pb-0">
						<h3 class="font-weight-light">Identifiant</h3>
					</v-col>
					<v-col>
						<p>
							{{ company.id }}
						</p>
					</v-col>
				</v-col>

				<v-col cols="12" sm="3">
					<v-col cols="12" class="pb-0">
						<h3 class="font-weight-light">SIRET</h3>
					</v-col>
					<v-col>
						<p>
							{{ company.siret }}
						</p>
					</v-col>
				</v-col>

				<v-col cols="12" sm="3" v-if="company.website">
					<v-col cols="12" class="pb-0">
						<h3 class="font-weight-light">Site web</h3>
					</v-col>
					<v-col>
						<p>
							<a :href="company.website" target="_blank" style="text-decoration: none;">{{ company.website }}</a>
						</p>
					</v-col>
				</v-col>

			</v-row>

		</v-card>

		<v-dialog v-model="dialogs.modify" max-width="950px">
			<v-card>
				<v-card-title>
					<span class="headline">Modifier {{ company.name }}</span>
				</v-card-title>
				<v-card-text>
					<v-container>

						<v-form ref="form">

							<v-row>
								<v-row>
									<v-col cols="12" sm="6">
										<span class="body-1 white--text">Détails</span>
										<v-row>
											<v-col cols="12" sm="8">
												<v-text-field label="Nom" hint="Nom de l'entreprise" v-model="company.name" :rules="this.$fieldsRules.required('nom')"></v-text-field>
											</v-col>
											<v-col cols="12" sm="4">
												<v-text-field disabled label="Code Entreprise" hint="Code de l'entreprise" v-model="company.nCompany"></v-text-field>
											</v-col>
										</v-row>

										<v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
											<template v-slot:activator="{ on, attrs }">
												<v-text-field v-model="formatDate" label="Date de naissance" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
											</template>
											<v-date-picker first-day-of-week="1" v-model="birthdate" @input="menu = false"></v-date-picker>
										</v-menu>

										<ImageUpload v-if="company" :companyID="companyID" type="company" label="Logo de l'entreprise" @newdata="company.avatar=$event" />

										<v-img v-if="company.avatar" contain max-height="150" :src="$functions.getImgLink(company.avatar)"></v-img>
									</v-col>

									<v-col cols="12" sm="6">
										<span class="body-1 white--text">Contact</span>

										<v-text-field label="N° de Téléphone" hint="N° de téléphone de l'entreprise" v-model="company.phone"></v-text-field>
										<v-text-field label="Adresse Mail" hint="Adresse mail de l'entreprise" v-model="company.email" :rules="this.$fieldsRules.email"></v-text-field>

										<v-text-field prefix="https://" label="Site Web" hint="Lien du site web de l'entreprise" v-model="company.website"></v-text-field>
									</v-col>
								</v-row>

								<v-divider class="col-12 mt-4"></v-divider>

								<v-row>
									<span class="body-1 white--text col-12">Adresse</span>

									<v-col cols="12" class="pb-0">
										<v-text-field label="Adresse" hint="Adresse de l'entreprise" v-model="company.address.street"></v-text-field>
									</v-col>

									<v-col cols="12" sm="6">
										<v-text-field label="Code Postal" hint="Code postal de l'entreprise" v-model="company.address.postalCode"></v-text-field>
										<v-text-field label="Région" hint="Région de l'entreprise" v-model="company.address.region"></v-text-field>
									</v-col>

									<v-col cols="12" sm="6">
										<v-text-field label="Ville" hint="Ville de l'entreprise" v-model="company.address.city"></v-text-field>
										<v-text-field label="Pays" hint="Pays de l'entreprise" v-model="company.address.country"></v-text-field>
									</v-col>
								</v-row>

								<v-divider class="col-12"></v-divider>

								<v-row>
									<span class="body-1 white--text col-12">Description</span>

									<v-col cols="12" class="py-0">
										<v-textarea class="py-0" hint="Description de l'entreprise" v-model="company.bio"></v-textarea>
									</v-col>
								</v-row>
							</v-row>

						</v-form>

					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="modifyCustomer()">
						Modifier
					</v-btn>
					<v-btn color="blue darken-1" text @click="dialogs.modify = false">
						Fermer
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>


<script>
	import ImageUpload from '@/components/graphics/ImageUpload';

	export default {
		props: {
			'company': {
				type: Object
			},
			'large': {
				type: Boolean,
				default: false
			},
			'admin': {
				type: Boolean,
				default: false
			},
			'companyID': {
				type: String,
			},

			'fromCustomer': {
				type: Boolean,
				default: false
			}
		},
		components: {
			ImageUpload
		},
		data() {
			return {
				date: null,
				menu: false,

				dialogs: {
					modify: false
				},

				birthdate: null
			}
		},
		computed: {
			formatDate() {
				return this.$moment(this.birthdate).format('DD/MM/YYYY');
			}
		},
		watch: {
			company: {
				deep: true,
				handler() {
					this.regenBirthdate();
				}
			}
		},
		methods: {
			regenBirthdate() {
					if(!this.birthdate && this.company.birthdate) this.birthdate = this.$functions.getDateFormat(this.company.birthdate, 'YYYY-MM-DD');
			},
			modifyCustomer() {
				const newData = {
					...this.company,
					birthdate: new Date(this.birthdate)
				};

				delete newData.id;
				delete newData.creationDate;
				delete newData.company;

				this.$db.collection("companies").doc(this.companyID).collection("companies").doc(this.company.id).set(newData, { merge: true }).then(() => {
					this.$store.dispatch('setSnackbar', {
						text: `L'entreprise ${this.company.name} a été modifié.`
					});

					this.dialogs.modify = false;
				});
			}
		},
		created() {
			this.regenBirthdate();
		}
	}
</script>