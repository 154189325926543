<template>
	<div class="FicheEntreprise pa-5">
		<Head title="Entreprise" :subTitle="company.name" offset="15vh" height="50vh" />

		<v-row class="mt-3">

			<v-col cols="12" sm="8">

				<InfoCompany :company="company" v-if="company.creationDate" large admin class="mb-3" />

				<v-card>
					<v-tabs v-model="seeModule" center-active>
						<v-tab v-for="item in modules" :key="item.id">
							<span v-if="item.quota && item.quota.quota != -1 && item.quota.progress.percentage > 100">
								<v-icon color="error" class="mr-2">mdi-alert</v-icon>
							</span>
							<span v-else-if="item.quota && item.quota.quota != -1 && item.quota.progress.percentage > 75">
								<v-icon color="warning" class="mr-2">mdi-alert</v-icon>
							</span>

							{{ item.name }}
						</v-tab>
					</v-tabs>

					<v-tabs-items v-model="seeModule">
						<v-tab-item v-for="item in modules" :key="item.id">
							<v-card class="pa-3" v-if="item.quota">

								<div class="mx-3">
									<v-alert type="error" v-if="item.quota && item.quota.quota != -1 && item.quota.progress.percentage > 100">
										Cette entreprise à dépassé de {{ item.quota.progress.percentage - 100 }}% de son quota !
									</v-alert>
									<v-alert type="warning" v-else-if="item.quota && item.quota.quota != -1 && item.quota.progress.percentage > 75">
										Cette entreprise à atteint {{ item.quota.progress.percentage }}% de son quota !
									</v-alert>

									<v-row>
										<v-col cols="12" sm="3">
											<v-list-item v-if="item.quota">
												<v-list-item-content>
													<v-list-item-title v-text="item.name"></v-list-item-title>
													<v-list-item-subtitle>
														{{ item.quota.size }} / <span v-if="item.quota.quota == -1">∞</span><span v-else>{{ item.quota.quota || 0 }}</span>
													</v-list-item-subtitle>
												</v-list-item-content>
											</v-list-item>
										</v-col>

										<v-spacer></v-spacer>

										<v-col cols="12" sm="1">
											<v-text-field v-if="item.quota" v-model="item.quota.quota" label="Quota" @change="statusQuota(item.id, item.quota.quota)" type="number"></v-text-field>
										</v-col>

										<v-col cols="12" sm="auto" class="mt-1">
											<v-btn outlined class="mr-2" v-if="item.quota" @click="statusModule(item.id, false)">Retirer le module</v-btn>
											<v-btn outlined class="mr-2" v-else @click="statusModule(item.id, true)">Ajouter le module</v-btn>

											<v-menu>

												<template v-slot:activator="{ on, attrs }">
													<v-btn v-bind="attrs" v-on="on" outlined>Gestion données</v-btn>
												</template>

												<v-card elevation="16">

													<v-btn outlined class="ml-2" @click="makeABackup(item.collection)" icon>
														<v-icon>mdi-database-export-outline</v-icon>
													</v-btn>

													<v-btn outlined class="ml-2" icon>
														<v-icon>mdi-database-import-outline</v-icon>
													</v-btn>

													<v-btn outlined class="ml-2" icon>
														<v-icon>mdi-database-remove-outline</v-icon>
													</v-btn>

												</v-card>
											</v-menu>
										</v-col>
									</v-row>
								</div>
								

								<DataTable :title="item.name" :type="item.collection" :company="id" canDelete showExpand :headers="$tableHeaders[item.collection]" :items="collectionData[item.collection]" v-if="collectionData[item.collection].length" itemKey="id" link="/record/company" />
							</v-card>
						</v-tab-item>
					</v-tabs-items>
				</v-card>
				
			</v-col>

			<v-col cols="12" sm="4">

				<v-card class="mb-5">
					<v-col cols="12">
						<span class="headline">Gestion</span>

						<v-row class="mt-2 mx-4">
							<!--<v-col cols="6">
								<v-btn text block color="error" @click="dialog.suspend = true">Suspendre</v-btn>
							</v-col>-->
							<v-col cols="6">
								<v-btn text block color="primary" @click="dialog.rgpd = true">Gestion RGPD</v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-card>
				
				<v-card class="mb-5">
					<v-col cols="12">
						<span class="headline">Modules</span>
						
						<div class="mt-2 mx-4">
							<v-row v-for="(item, index) in modules" :key="item.id" class="py-1">
								<v-col cols="auto" class="py-0">
									<v-btn icon @click="seeModule = index">
										<v-icon>mdi-arrow-right-bottom</v-icon>
									</v-btn>
								</v-col>
								<v-col class="py-0 mt-2">
									<v-list-item-title v-text="item.name"></v-list-item-title>
								</v-col>
								<v-col cols="auto" class="py-0 mt-2">
									<v-progress-circular v-if="item.quota" width="2" size="23" :rotate="90" :value="item.quota.progress.percentage" :color="item.quota.progress.color"></v-progress-circular>
									<v-icon v-if="!item.quota" color="error">mdi-close</v-icon>
								</v-col>
							</v-row>
						</div>
					</v-col>
				</v-card>

			</v-col>

		</v-row>

		<v-dialog v-model="dialog.rgpd" max-width="50%">
			<v-card>
				<v-card-title class="headline">
					RGPD
				</v-card-title>

				<v-card-text>
					<p>Données concernées : profil entreprise.</p>

					<v-row class="my-4">

						<v-col cols="6">
							<v-btn text @click="makeATinyBackup()">Obtenir une copie des données</v-btn>
						</v-col>

						<v-col cols="6">
							<p>La copie des données sera téléchargée sous un fichier JSON.</p>
						</v-col>

					</v-row>

					<v-row class="my-4">

						<v-col cols="6">
							<v-btn text disabled>Envoyer par mail une copie des données</v-btn>
						</v-col>

						<v-col cols="6">
							<p>Le fichier de données sera envoyé à {{ company.email }}.</p>
						</v-col>

					</v-row>

					<v-row class="my-4">

						<v-col cols="6">
							<v-btn text disabled color="error">Supprimer toutes les données</v-btn>
						</v-col>

						<v-col cols="6">
							<p>Toutes les données seront supprimées définitivement.</p>
						</v-col>

					</v-row>

				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="dialog.rgpd = false">
						Fermer
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialog.getBackup" max-width="30%">
			<v-card>
				<v-card-title class="headline">
					Sauvegarde terminée !
				</v-card-title>
				<v-card-text>
					<p>Le fichier à bien été généré et téléchargé !</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="dialog.getBackup = false">
						OK !
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

	</div>
</template>

<script>
	const { saveAs } = require('file-saver');

	import Head from '@/components/graphics/head';

	import InfoCompany from '@/components/modules/@company/InfoCompany';
	import DataTable from '@/components/modules/#global/DataTable';

	export default {
		props: {
			'id': {
				type: String
			}
		},
		components: {
			InfoCompany,
			DataTable,
			Head
		},
		name: 'AdminCompany',
		data() {
			return {
				dialog: {
					getBackup: false,
					rgpd: false
				},

				company: this.$models.company,

				userData: this.$models.user,

				modules: [],

				seeModule: 0,

				collectionData: {},
				
				headers: [{
						text: "",
						value: "status",
						width: 50,
					},
					{
						text: "",
						sortable: false,
						value: "avatar",
						width: 50,
					},
					{
						text: "n°",
						value: "nClient",
						width: 65,
					},
					{
						text: "Nom",
						value: "lastName",
						width: 75,
					},
					{
						text: "Prénom",
						value: "firstName",
						width: 0,
					},
					{
						text: "",
						sortable: false,
						value: "manage",
						width: 140,
					},
				]
			}
		},
		methods: {
			makeATinyBackup() {
				var backup = {};

				this.$db.collection("companies").doc(this.id).get().then((doc) => {
					backup = {
						...doc.data(),
						id: doc.id
					};

					var blob = new Blob([JSON.stringify(backup)], {
						type: "text/plain;charset=utf-8"
					});

					saveAs(blob, `${this.id}_companyProfile-${this.$moment().format("DD-MM-YYYY@HH-mm")}.json`);
				});
			},
			makeABackup(collection) {
				var backup = {};

				this.$db.collection("companies").doc(this.id).collection(collection).get().then((ref) => {
					ref.forEach((doc) => {
						backup[doc.id] = {
							...doc.data(),
							id: doc.id
						};

						/*var subcollections = this.$functions.findWithAttr(this.modules, "collection", collection);

						if(subcollections) backup[doc.id].subcollections = {};

						this.modules[subcollections].subcollections.forEach((collection2) => {
							var subdocuments = [];

							this.$db.collection("companies").doc(this.id).collection(collection).doc(doc.id).collection(collection2).get().then((ref2) => {
								ref2.forEach((doc2) => {
									subdocuments.push({
										...doc2.data(),
										id: doc2.id
									});
								});

								backup[doc.id].subcollections[`_${collection2}`] = subdocuments;
							});
						});*/
					});

					/*console.log(1, backup)

					for (const record in backup) {
						console.log(2, record, backup[record].subcollections)
						
						for (const coll in backup[record].subcollections) {
							console.log(3, coll, JSON.stringify(coll));
						}
					}*/

					var blob = new Blob([JSON.stringify(backup)], {
						type: "text/plain;charset=utf-8"
					});

					saveAs(blob, `${this.id}_${collection}-${this.$moment().format("DD-MM-YYYY@HH-mm")}.json`);

					this.dialog.getBackup = true;
				})
			},
			getQuotas(id, collection) {
				this.$db.collection("companies").doc(this.id).collection("modules").doc(id).get().then((doc) => {
					if(!doc.data()) return;

					var quota = doc.data().quota || -1;

					this.$db.collection("companies").doc(this.id).collection(collection).get().then((doc) => {
						let size = doc.size;
						if(collection === "ia") {
							size = 0;

							doc.forEach((doc) => {
								size += doc.data().tokens;
							});
						}

						var percentage = Math.floor(100 * size / quota);
						if(quota == -1) percentage = 100;

						var color = "success";
						if(quota != -1) {
							if(percentage > 75) color = "warning";
							if(percentage >= 100) color = "error";
						}

						this.modules[this.$functions.findWithAttr(this.modules, "id", id)].quota = {
							size: size,
							quota: quota,
							progress: {
								percentage: percentage,
								color: color
							}
						};

					});
				});
			},
			getModules() {
				this.modules = [];

				var newModules = [];

				this.$db.collection("modules").get().then(async (res) => {
					res.forEach((module) => {
						this.collectionData[module.data().collection] = [];
					});

					res.forEach((module) => {
						this.$db.collection("companies").doc(this.id).collection(module.data().collection).get().then((ref) => {
							ref.forEach((doc) => {
								this.collectionData[module.data().collection].push({
									...doc.data(),
									id: doc.id
								});
							});
						});

						newModules.push({
							...module.data(),
							id: module.id
						});

						this.getQuotas(module.id, module.data().collection);
					});

					this.modules = newModules;
				});
			},

			statusModule(idModule, status) {
				this.$db.collection("companies").doc(this.id).set({ modulesModified: new Date() }, { merge: true });

				if(status) {
					this.$db.collection("companies").doc(this.id).collection("modules").doc(idModule).set({
						giveDate: new Date()
					}).then(() => {
						this.$store.dispatch('setSnackbar', {
							text: `Le module à bien été activé !`,
						});
					});
				}
				else this.$katana.removeAllDataFromDocument(this.$db.collection("companies").doc(this.id).collection("modules").doc(idModule), "Le module à bien été désactivé !");

				this.getModules();
			},

			statusQuota(idModule, quota) {
				this.$db.collection("companies").doc(this.id).set({ modulesModified: new Date() }, { merge: true });

				this.$db.collection("companies").doc(this.id).collection("modules").doc(idModule).set({
					quota: quota
				}, {
					merge: true
				}).then(() => {
					this.$store.dispatch('setSnackbar', {
						text: `Le quota de ce module à été modifié !`,
					});

					this.modules.forEach((module) => {
						this.getQuotas(module.id, module.collection);
					});
				});
			}
		},
		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot((doc) => {
				this.userData = {
					...doc.data(),
					id: doc.id
				};

				this.$db.collection("companies").doc(this.id).onSnapshot(doc => {
					this.company = {
						...doc.data(),
						creationDate: this.$moment(),
						id: doc.id
					};

					document.title = `${this.company.name} - Entreprise n°${this.company.nCompany}`;

					this.getModules();
				});
			});
		}
	}
</script>